import React from "react";
import {
  Edit,
  TextInput,
  DisabledInput,
  BooleanInput,
  required,
  email,
  minLength,
  maxLength,
  TabbedForm,
  FormTab,
  ArrayField,
  Datagrid,
  TextField,
  showNotification as showNotificationAction,
} from "react-admin";
import { connect } from "react-redux";
import { refreshView as refreshViewAction } from "ra-core";
import DeleteButton from "./DeleteButton";
import { useMutation } from "@apollo/react-hooks";
import UserSearch from "../../components/UserSearch";
import { LINK_GUARDIAN_TO_USER } from "./GqlQueries";
import { LinkGuardianVariables, LinkGuardian } from "./types/LinkGuardian";

import { hasPermission } from "../../services/utils";

const Title = ({ record }: { record: { emailAddress: string } }) => {
  return <span>User {record.emailAddress}</span>;
};

const emailValidators = [required(), email(), minLength(1), maxLength(320)];

const WrapUserSearch = ({
  onSelect,
  record,
}: {
  onSelect: (user: any) => void;
  record: {
    guardians: { id: string }[];
  };
}) => {
  return <UserSearch selected={record.guardians} onSelect={onSelect} />;
};

const UserEdit: React.FC = ({
  showNotification,
  refreshView,
  record,
  ...props
}: any) => {
  const { id } = props;

  const [linkGuardian, { loading }] = useMutation<
    LinkGuardian,
    LinkGuardianVariables
  >(LINK_GUARDIAN_TO_USER);

  const linkUser = async (role: string, user: { emailAddress: string }) => {
    try {
      const variables = {
        variables: { id: id, guardianEmailAddress: user.emailAddress },
      };

      await linkGuardian(variables);

      showNotification("Guardian added to user");
      refreshView();
    } catch {
      showNotification(`Adding guardian to user failed!`, "warning");
    }
    refreshView();
  };

  return (
    <Edit title={<Title {...props} />} undoable={false} {...props}>
      <TabbedForm>
        <FormTab label="Basic">
          <DisabledInput source="id" />
          <TextInput
            label="Email address"
            source="emailAddress"
            validate={emailValidators}
          />
          <TextInput
            label="Given name"
            source="givenName"
            validate={maxLength(64)}
          />
          <TextInput
            label="Family name"
            source="familyName"
            validate={maxLength(64)}
          />
          {hasPermission(props.permissions, ["superadmin"]) && (
            <BooleanInput label="Super admin" source="superAdmin" />
          )}
          <BooleanInput label="Enabled" source="enabled" />
        </FormTab>
        <FormTab label="Guardians" path="guardians">
          <ArrayField source="guardians" addLabel={false}>
            <Datagrid>
              <TextField source="emailAddress" />
              <DeleteButton parentRecordId={id} role="teacher" />
            </Datagrid>
          </ArrayField>
          {!loading && (
            <WrapUserSearch
              {...props}
              role="teacher"
              onSelect={linkUser.bind(null, "teacher")}
            />
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const enhance = connect(
  null,
  { showNotification: showNotificationAction, refreshView: refreshViewAction },
);

export default enhance(UserEdit);
