import prop from "ramda/es/prop";
import groupBy from "ramda/es/groupBy";
import toPairs from "ramda/es/toPairs";
import filter from "ramda/es/filter";

export const defaultLanguageTranslation = (value: any) => {
  if (!Array.isArray(value)) {
    return;
  }
  return value.some((t: any) => t.languageTag === "fi-FI")
    ? undefined
    : "Translation for default language Finnish not found";
};

export const translationLength = (min: number, max: number) => (value: any) => {
  if (!Array.isArray(value)) {
    return;
  }
  const translation = value.find(
    (t: any) =>
      !t.translation ||
      t.translation.length < min ||
      t.translation.length > max,
  );
  return !translation
    ? undefined
    : `Translation length for language ${languageTagToLanguage(
        translation.languageTag,
      )} must be between ${min} and ${max}`;
};

export const translationLanguageTag = (value: any) => {
  if (!Array.isArray(value)) {
    return;
  }
  const translation = value.some(
    (t: any) => !["fi-FI", "sv-FI"].includes(t.languageTag),
  );
  return !translation
    ? undefined
    : `One or more translations do not have language selected`;
};

export const duplicateLanguageTag = (value: any) => {
  if (!Array.isArray(value)) {
    return;
  }
  const groubed = groupBy(prop("languageTag") as any)(value);
  const pairs = toPairs(groubed);
  const duplicates = filter((val: any) => val[1].length > 1, pairs);
  return duplicates.length > 0
    ? `Duplicated translations for: ${duplicates
        .map(d => languageTagToLanguage(d[0]))
        .join(", ")}`
    : undefined;
};

function languageTagToLanguage(languageTag: string) {
  if (languageTag === "fi-FI") {
    return "Finnish";
  } else if (languageTag === "sv-FI") {
    return "Swedish";
  }
  return "Unknown";
}
