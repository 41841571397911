import React, { useState, useEffect } from "react";
import { Admin, Resource, Delete } from "react-admin";
import { GET_ONE } from "ra-core";
import buildGraphQLProvider from "ra-data-graphql-simple";
import firebase from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

import OrganizationList from "./resources/organization/OrganizationList";
import OrganizationEdit from "./resources/organization/OrganizationEdit";
import OrganizationCreate from "./resources/organization/OrganizationCreate";

import UserList from "./resources/user/UserList";
import UserEdit from "./resources/user/UserEdit";
import UserCreate from "./resources/user/UserCreate";
import LoginPage from "./resources/login/Login";
import authProvider from "./services/authProvider";
import config from "./config";
import raQueryOverrides from "./raQueryOverrides";

function hasPermission(permissions: string, allowedPermissions: string[]) {
  return allowedPermissions.includes(permissions);
}
function cleanTypename(operation: any) {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }
}

function omitTypename(key: string, value: string) {
  return key === "__typename" ? undefined : value;
}

const client: any = new ApolloClient({
  uri: config.graphqlEndpoint,
  request: async operation => {
    if (operation.operationName === "IntrospectionQuery") {
      return;
    }

    cleanTypename(operation);
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new Error("Not logged in");
    }
    const token = await user.getIdToken(false);
    if (!token) {
      throw new Error("Not logged in");
    }
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
  onError: ({ response }) => {
    if (response && response.errors && response.errors.length > 0) {
      const error: any = response.errors[0];
      if (error.code === 409) {
        error.message = `Value on field ${error.field} already exist.`;
      }
    }
  },
});

const App = () => {
  const [, loading, error] = useAuthState(firebase.auth());

  const [dataProvider, setDataProvider]: [
    typeof DataProvider | null,
    any,
  ] = useState(null);

  useEffect(() => {
    buildGraphQLProvider({
      buildQuery: raQueryOverrides,
      client,
      introspection: {
        operationNames: {
          [GET_ONE]: (resource: { name: string }) =>
            resource.name.substring(0, 1).toLocaleLowerCase() +
            resource.name.substring(1),
        },
      },
    }).then((dp: typeof DataProvider) => {
      setDataProvider(() => dp);
    });
  }, []);

  if (!dataProvider) {
    return null;
  }

  if (loading) {
    return null;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <ApolloProvider client={client}>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
      >
        {(permissions: string) => [
          hasPermission(permissions, ["superadmin", "organizationadmin"]) ? (
            <Resource
              name="User"
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
              remove={
                hasPermission(permissions, ["superadmin"]) ? Delete : undefined
              }
            />
          ) : null,
          <Resource
            name="Organization"
            list={OrganizationList}
            edit={OrganizationEdit}
            create={
              hasPermission(permissions, ["superadmin"])
                ? OrganizationCreate
                : undefined
            }
            remove={
              hasPermission(permissions, ["superadmin"]) ? Delete : undefined
            }
          />,
        ]}
      </Admin>
    </ApolloProvider>
  );
};

export default App;
