import { buildQuery } from "ra-data-graphql-simple";
import gql from "graphql-tag";

const queryOverrides = (introspection: any) => (
  fetchType: any,
  resource: any,
  params: any,
) => {
  const builtQuery = buildQuery(introspection)(fetchType, resource, params);
  if (resource === "Organization" && fetchType === "GET_ONE") {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
        query organization($id: String!) {
          data: organization(id: $id) {
            id
            domain
            name
            names {
              languageTag
              translation
            }
            teachers {
              id
              emailAddress
            }
            admins {
              id
              emailAddress
            }
            learners {
              id
              emailAddress
            }
          }
        }
      `,
    };
  }
  console.log(resource, fetchType, builtQuery);

  if (resource === "User" && fetchType === "UPDATE") {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      query: gql`
        mutation updateUser(
          $id: String!
          $emailAddress: String!
          $givenName: String!
          $familyName: String!
          $superAdmin: Boolean!
          $phoneNumber: String
          $enabled: Boolean!
        ) {
          data: updateUser(
            id: $id
            emailAddress: $emailAddress
            givenName: $givenName
            familyName: $familyName
            superAdmin: $superAdmin
            phoneNumber: $phoneNumber
            enabled: $enabled
          ) {
            id
            emailAddress
            givenName
            familyName
            guardians {
              id
              __typename
            }
            wards {
              id
              __typename
            }
            phoneNumber
            superAdmin
            enabled
            language
            organization {
              id
              __typename
            }
            photoUrl
            __typename
          }
        }
      `,
    };
  }

  if (resource === "User" && fetchType === "GET_LIST") {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      query: gql`
        query allUsers(
          $filter: UsersFilter
          $page: Float
          $perPage: Float
          $sortField: String
          $sortOrder: String
        ) {
          items: allUsers(
            filter: $filter
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
          ) {
            id
            emailAddress
            givenName
            familyName
            guardians {
              id
              __typename
            }
            wards {
              id
              __typename
            }
            phoneNumber
            superAdmin
            enabled
            activities {
              id
              __typename
            }
            language
            organization {
              id
              __typename
            }
            photoUrl
            __typename
          }
          total: _allUsersMeta(
            filter: $filter
            page: $page
            perPage: $perPage
          ) {
            count
            __typename
          }
        }
      `,
    };
  }

  if (resource === "User" && fetchType === "GET_MANY_REFERENCE") {
    const variables = {
      page: Math.max(0, params.pagination.page - 1),
      perPage: params.pagination.perPage,
      sortField: "emailAddress",
      sortOrder: "ASC",
      filter: { ...builtQuery.variables.filter, role: params.filter.role },
    };
    if (params.filter.search && params.filter.search.length) {
      variables.filter.search = params.filter.search;
    }

    console.log(params);
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      query: gql`
        query allUsers(
          $filter: UsersFilter
          $page: Float
          $perPage: Float
          $sortField: String
          $sortOrder: String
        ) {
          items: allUsers(
            filter: $filter
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
          ) {
            id
            emailAddress
            givenName
            familyName
            guardians {
              id
              __typename
            }
            wards {
              id
              __typename
            }
            phoneNumber
            superAdmin
            enabled
            activities {
              id
              __typename
            }
            language
            organization {
              id
              __typename
            }
            photoUrl
            __typename
          }
          total: _allUsersMeta(
            filter: $filter
            page: $page
            perPage: $perPage
          ) {
            count
            __typename
          }
        }
      `,
      variables,
    };
  }
  if (resource === "User" && fetchType === "GET_ONE") {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
        query user($id: String!) {
          data: user(id: $id) {
            id
            emailAddress
            givenName
            familyName
            superAdmin
            enabled
            guardians {
              id
              emailAddress
            }
          }
        }
      `,
    };
  }

  return builtQuery;
};

export default queryOverrides;
