import React, { useState, CSSProperties } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import differenceBy from "lodash/differenceBy";

import Downshift from "downshift";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { Users, UsersVariables, Users_allUsers as User } from "./types/Users";

const SEARCH_USERS = gql`
  query Users($search: String) {
    allUsers(filter: { search: $search }) {
      id
      emailAddress
    }
  }
`;

type RenderInputProps = TextFieldProps & {
  ref?: React.Ref<HTMLDivElement>;
};

function renderInput(inputProps: RenderInputProps) {
  const { InputProps, ref, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: ref,
        ...InputProps,
      }}
      {...other}
    />
  );
}

export interface UserSelectProps {
  onSelect: (user: { emailAddress: string }) => void;
  selected?: { id: string }[];
}

const UserSelect: React.FC<UserSelectProps> = ({ onSelect, selected }) => {
  const [search, setSearch] = useState("");
  const [user, setLinkUser] = useState();

  const selectUser = (user: User) => {
    setLinkUser(user);
  };

  const onAddUser = () => {
    if (user) {
      onSelect(user);
    } else {
      onSelect({
        emailAddress: search,
      });
    }
  };

  return (
    <div style={{ padding: "32px 0 0" }}>
      <Grid container spacing={16} alignItems="flex-end">
        <Grid item xs={6}>
          <Downshift
            onChange={selectUser}
            itemToString={item => (item ? item.emailAddress : "")}
          >
            {({
              clearSelection,
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              inputValue,
              isOpen,
              openMenu,
              selectedItem,
            }) => {
              const {
                onBlur,
                onChange,
                onFocus,
                ...inputProps
              } = getInputProps({
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(event.target.value);
                  if (event.target.value === "") {
                    clearSelection();
                  }
                },
                onFocus: openMenu,
                placeholder: "Search user",
              });

              return (
                <div>
                  {renderInput({
                    label: "Select user to link",
                    InputLabelProps: getLabelProps({ shrink: true } as any),
                    InputProps: { onBlur, onChange, onFocus },
                    inputProps,
                  })}
                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Menu
                        getItemProps={getItemProps}
                        selectedItem={selectedItem}
                        selected={selected}
                        search={inputValue}
                      ></Menu>
                    ) : null}
                  </div>
                </div>
              );
            }}
          </Downshift>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" color="primary" onClick={onAddUser}>
            Link user
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserSelect;

interface MenuProps {
  search: string | null;
  selectedItem: string | null;
  selected?: { id: string }[];
  getItemProps: (obj: any) => any;
}

const menuStyles: CSSProperties = {
  position: "absolute",
  zIndex: 1,
};

const Menu: React.FC<MenuProps> = ({ search, selected, getItemProps }) => {
  const { data, error, loading } = useQuery<Users, UsersVariables>(
    SEARCH_USERS,
    {
      variables: { search },
    },
  );
  if (loading) {
    return null;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const usersFiltered = differenceBy(
    data ? data.allUsers : [],
    selected || [],
    "id",
  );

  return (
    <Paper square style={menuStyles}>
      {usersFiltered.map((user: User) => (
        <MenuItem
          {...getItemProps({ item: user })}
          key={user.id}
          component="div"
        >
          {user.emailAddress}
        </MenuItem>
      ))}
    </Paper>
  );
};
