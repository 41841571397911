import gql from "graphql-tag";

export const LINK_TEACHER_TO_ORGANIZATION = gql`
  mutation LinkTeacherToOrganization($id: String!, $emailAddress: String!) {
    linkTeacherToOrganization(id: $id, emailAddress: $emailAddress)
  }
`;

export const LINK_ADMIN_TO_ORGANIZATION = gql`
  mutation LinkAdminToOrganization($id: String!, $emailAddress: String!) {
    linkAdminToOrganization(id: $id, emailAddress: $emailAddress)
  }
`;

export const LINK_LEARNER_TO_ORGANIZATION = gql`
  mutation LinkLearnerToOrganization($id: String!, $emailAddress: String!) {
    linkLearnerToOrganization(id: $id, emailAddress: $emailAddress)
  }
`;

export const DELETE_ORGANIZATION_TEACHER = gql`
  mutation DeleteOrganizationTeacher($id: String!, $userId: String!) {
    deleteOrganizationTeacher(id: $id, userId: $userId)
  }
`;

export const DELETE_ORGANIZATION_ADMIN = gql`
  mutation DeleteOrganizationAdmin($id: String!, $userId: String!) {
    deleteOrganizationAdmin(id: $id, userId: $userId)
  }
`;

export const DELETE_ORGANIZATION_LEARNER = gql`
  mutation DeleteOrganizationLearner($id: String!, $userId: String!) {
    deleteOrganizationLearner(id: $id, userId: $userId)
  }
`;
