import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from "react-admin";
import firebase from "firebase/app";

const allowedRoles = ["superadmin", "organizationadmin"];

let user: firebase.User | null = firebase.auth().currentUser;
let rolePromise: Promise<string | void> = getRole();

firebase.auth().onAuthStateChanged(
  user => {
    if (user) {
      login();
    } else {
      logout();
    }
  },
  () => {
    logout();
  },
);

const authProvider: AuthProvider = async (type, params: any) => {
  if (type === AUTH_LOGIN) {
    return Promise.resolve();
  }
  if (type === AUTH_LOGOUT) {
    firebase.auth().signOut();
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    if (params.networkError && params.networkError.statusCode === 401) {
      return logout();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    if (!user) {
      return Promise.reject();
    }
    return rolePromise;
  }
  if (type === AUTH_GET_PERMISSIONS) {
    return rolePromise;
  }
  return Promise.reject();
};

function login() {
  user = firebase.auth().currentUser;
  rolePromise = getRole();
  return !!user ? Promise.resolve() : Promise.reject();
}

function logout() {
  user = null;
  rolePromise = Promise.reject();
  return Promise.resolve();
}

async function getRole(): Promise<string | void> {
  if (!user) {
    return Promise.reject();
  }
  const idTokenResult = await user.getIdTokenResult(true);
  console.log("idTokenResult", idTokenResult);
  if (
    !idTokenResult.claims.roles ||
    idTokenResult.claims.roles.length === 0 ||
    !allowedRoles.includes(idTokenResult.claims.roles[0])
  ) {
    return Promise.reject();
  }
  return Promise.resolve(idTokenResult.claims.roles[0]);
}

export default authProvider;
