import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  DisabledInput,
  email,
  minLength,
  maxLength,
} from "react-admin";
import { parse } from "query-string";

import { hasPermission } from "../../services/utils";

const emailValidators = [required(), email(), minLength(1), maxLength(320)];
export default (props: {
  permissions: string;
  location: { search: string };
}) => {
  const { organizationId: organizationIdString }: any = parse(
    props.location.search,
  );
  let redirect,
    organizationId,
    organizationField,
    superAdminField,
    title = "Create a user";

  if (organizationIdString) {
    redirect = `/Organization/${organizationIdString}/1`;
    organizationId = parseInt(organizationIdString, 10);
    organizationField = <DisabledInput source="organizationId" />;
    title = "Create admin to the organization";
  } else if (hasPermission(props.permissions, ["superadmin"])) {
    superAdminField = <BooleanInput label="Super admin" source="superAdmin" />;
  }

  return (
    <Create title={title} {...props}>
      <SimpleForm defaultValue={{ organizationId }} redirect={redirect}>
        {organizationField}
        <TextInput
          label="Email address"
          source="emailAddress"
          validate={emailValidators}
        />
        <TextInput
          label="Given name"
          source="givenName"
          validate={maxLength(64)}
        />
        <TextInput
          label="Family name"
          source="familyName"
          validate={maxLength(64)}
        />
        {superAdminField}
      </SimpleForm>
    </Create>
  );
};
