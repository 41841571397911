import React from "react";
import { useMutation } from "@apollo/react-hooks";

import {
  Button,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from "react-admin";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  DELETE_ORGANIZATION_ADMIN,
  DELETE_ORGANIZATION_LEARNER,
  DELETE_ORGANIZATION_TEACHER,
} from "./GqlQueries";

import {
  DeleteOrganizationAdmin,
  DeleteOrganizationAdminVariables,
} from "./types/DeleteOrganizationAdmin";

import {
  DeleteOrganizationTeacher,
  DeleteOrganizationTeacherVariables,
} from "./types/DeleteOrganizationTeacher";

import {
  DeleteOrganizationLearner,
  DeleteOrganizationLearnerVariables,
} from "./types/DeleteOrganizationLearner";

interface DeleteButtonProps {
  parentRecordId: string;
  record: any;
  showNotification: (msg: string, type?: any) => void;
  refreshView: () => void;
  role: string;
}

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const DeleteButton: React.FC<DeleteButtonProps> = ({
  parentRecordId,
  record,
  showNotification,
  refreshView,
  role,
}) => {
  const [deleteTeacherLink] = useMutation<
    DeleteOrganizationTeacher,
    DeleteOrganizationTeacherVariables
  >(DELETE_ORGANIZATION_TEACHER, {
    variables: { id: parentRecordId, userId: record.id },
  });

  const [deleteAdminLink] = useMutation<
    DeleteOrganizationAdmin,
    DeleteOrganizationAdminVariables
  >(DELETE_ORGANIZATION_ADMIN, {
    variables: { id: parentRecordId, userId: record.id },
  });

  const [deleteLearnerLink] = useMutation<
    DeleteOrganizationLearner,
    DeleteOrganizationLearnerVariables
  >(DELETE_ORGANIZATION_LEARNER, {
    variables: { id: parentRecordId, userId: record.id },
  });

  const removeLink: () => void = async () => {
    try {
      if (role === "teacher") await deleteTeacherLink();
      if (role === "admin") await deleteAdminLink();
      if (role === "learner") await deleteLearnerLink();
      showNotification(`${capitalize(role)} deleted from organization.`);
      refreshView();
    } catch {
      showNotification(`Deleting ${role} from organization failed!`, "warning");
    }
  };

  return (
    <Button onClick={removeLink} label="Remove">
      <DeleteIcon />
    </Button>
  );
};

const enchance = connect(
  null,
  {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  },
);

export default enchance(DeleteButton);
