import React, { Component } from "react";
import { connect } from "react-redux"; // eslint-disable-line import/no-extraneous-dependencies
import firebase from "firebase/app";
import { userLogin, Notification } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import background from "./background.jpg";
import GoogleLogo from "./g-normal.png";

const styles: () => {} = () => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    background: `url(${background}) no-repeat center center fixed`,
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  actions: {
    padding: "1em 1em 1em 1em",
  },
});

interface LoginPageProps {
  userLogin: () => {};
  classes: any;
}

const LoginPage: any = ({ userLogin: login, classes }: LoginPageProps) => {
  const loginWithGoogle = async () => {
    try {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(googleProvider);
      if (result.user) {
        login();
      }
    } catch (error) {
      console.log(error);
      console.log(error.stack);
    }
  };
  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <CardActions className={classes.actions}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            aria-label="Google login"
            data-cy="login-button-google"
            onClick={loginWithGoogle}
            fullWidth
          >
            <img alt="Google" src={GoogleLogo} />
            Login with google
          </Button>
        </CardActions>
      </Card>
      <Notification />
    </div>
  );
};

export default withStyles(styles)(
  connect(
    null,
    { userLogin },
  )(LoginPage),
);
