import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  minLength,
  maxLength,
} from "react-admin";
import {
  defaultLanguageTranslation,
  translationLanguageTag,
  translationLength,
  duplicateLanguageTag,
} from "../../services/validators";

const nameValidators = [
  defaultLanguageTranslation,
  duplicateLanguageTag,
  translationLength(1, 320),
  translationLanguageTag,
];

const formDefaultValues = {
  names: [
    {
      languageTag: "fi-FI",
    },
  ],
};

export default (props: object) => (
  <Create title="Create a organization" {...props}>
    <SimpleForm defaultValue={formDefaultValues}>
      <TextInput
        label="Domain"
        source="domain"
        validate={[required(), minLength(1), maxLength(100)]}
      />
      <ArrayInput source="names" validate={nameValidators}>
        <SimpleFormIterator>
          <SelectInput
            label="Language"
            source="languageTag"
            choices={[
              { id: "fi-FI", name: "Finnish" },
              { id: "sv-FI", name: "Swedish" },
            ]}
          />
          <TextInput source="translation" validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
