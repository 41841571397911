import gql from "graphql-tag";

export const LINK_GUARDIAN_TO_USER = gql`
  mutation LinkGuardian($id: String!, $guardianEmailAddress: String!) {
    linkGuardian(id: $id, guardianEmailAddress: $guardianEmailAddress)
  }
`;

export const DELETE_GUARDIAN_FROM_USER = gql`
  mutation DeleteGuardian($id: String!, $guardianId: String!) {
    deleteGuardian(id: $id, guardianId: $guardianId)
  }
`;
