import React from "react";
import { useMutation } from "@apollo/react-hooks";

import {
  Button,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from "react-admin";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";

import { LINK_GUARDIAN_TO_USER, DELETE_GUARDIAN_FROM_USER } from "./GqlQueries";
import {
  DeleteGuardian,
  DeleteGuardianVariables,
} from "./types/DeleteGuardian";

interface DeleteButtonProps {
  parentRecordId: string;
  record: any;
  showNotification: (msg: string, type?: any) => void;
  refreshView: () => void;
  role: string;
}

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const DeleteButton: React.FC<DeleteButtonProps> = ({
  parentRecordId,
  record,
  showNotification,
  refreshView,
  role,
}) => {
  const [deleteGuardianLink] = useMutation<
    DeleteGuardian,
    DeleteGuardianVariables
  >(DELETE_GUARDIAN_FROM_USER, {
    variables: { id: parentRecordId, guardianId: record.id },
  });

  const removeLink: () => void = async () => {
    try {
      await deleteGuardianLink();
      showNotification("Guardian deleted from user.");
      refreshView();
    } catch {
      showNotification("Deleting guardian from user failed!", "warning");
    }
  };

  return (
    <Button onClick={removeLink} label="Remove">
      <DeleteIcon />
    </Button>
  );
};

const enchance = connect(
  null,
  {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  },
);

export default enchance(DeleteButton);
