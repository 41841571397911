import React from "react";
import {
  Filter,
  SearchInput,
  List,
  Datagrid,
  TextField,
  EditButton,
} from "react-admin";

const UserFilter = (props: object) => (
  <Filter {...props}>
    <SearchInput source="search" resettable alwaysOn />
  </Filter>
);

export default (props: object) => {
  return (
    <List filters={<UserFilter />} {...props}>
      <Datagrid>
        <TextField source="emailAddress" />
        <TextField source="givenName" />
        <TextField source="familyName" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
