import React, { useState } from "react";
import {
  Edit,
  TextInput,
  DisabledInput,
  FormTab,
  required,
  Datagrid,
  TextField,
  EditButton,
  TabbedForm,
  ReferenceInput,
  SelectInput,
  showNotification as showNotificationAction,
  ArrayInput,
  SimpleFormIterator,
  Pagination,
  ReferenceManyField,
  minLength,
  maxLength,
} from "react-admin";

import MuiTextField from "@material-ui/core/TextField";

import { connect } from "react-redux";
import { refreshView as refreshViewAction } from "ra-core";
import { useMutation } from "@apollo/react-hooks";

import UserSearch from "../../components/UserSearch";

import DeleteButton, { capitalize } from "./DeleteButton";

import {
  LINK_TEACHER_TO_ORGANIZATION,
  LINK_ADMIN_TO_ORGANIZATION,
  LINK_LEARNER_TO_ORGANIZATION,
} from "./GqlQueries";

import {
  LinkTeacherToOrganization,
  LinkTeacherToOrganizationVariables,
} from "./types/LinkTeacherToOrganization";

import {
  LinkAdminToOrganization,
  LinkAdminToOrganizationVariables,
} from "./types/LinkAdminToOrganization";

import {
  LinkLearnerToOrganization,
  LinkLearnerToOrganizationVariables,
} from "./types/LinkLearnerToOrganization";
import {
  defaultLanguageTranslation,
  translationLanguageTag,
  translationLength,
  duplicateLanguageTag,
} from "../../services/validators";

const WrapUserSearch = ({
  onSelect,
  record,
  role,
}: {
  onSelect: (user: any) => void;
  record: {
    admins: { id: string }[];
    teachers: { id: string }[];
    learners: { id: string }[];
  };
  role: "teacher" | "admin" | "learner";
}) => {
  let selected: { id: string }[] = [];
  if (role === "teacher") selected = record.teachers;
  if (role === "admin") selected = record.admins;
  if (role === "learner") selected = record.learners;
  return <UserSearch selected={selected} onSelect={onSelect} />;
};

const Title = ({ record }: { record: { name: string } }) => {
  return <span>Organization {record.name}</span>;
};

const nameValidators = [
  defaultLanguageTranslation,
  duplicateLanguageTag,
  translationLength(1, 320),
  translationLanguageTag,
];

let _searchTimer: NodeJS.Timeout;
const OrganizationEdit: React.FC = ({
  showNotification,
  refreshView,
  record,
  permissions,
  ...props
}: any) => {
  const { id } = props;

  const onSearch = (value: string) => {
    clearTimeout(_searchTimer);
    _searchTimer = setTimeout(() => setSearch(value), 500);
  };
  const [search, setSearch] = useState();

  const [linkTeacher, { loading: loadingTeacher }] = useMutation<
    LinkTeacherToOrganization,
    LinkTeacherToOrganizationVariables
  >(LINK_TEACHER_TO_ORGANIZATION);

  const [linkAdmin, { loading: loadingAdmin }] = useMutation<
    LinkAdminToOrganization,
    LinkAdminToOrganizationVariables
  >(LINK_ADMIN_TO_ORGANIZATION);

  const [linkLearner, { loading: loadingLearner }] = useMutation<
    LinkLearnerToOrganization,
    LinkLearnerToOrganizationVariables
  >(LINK_LEARNER_TO_ORGANIZATION);

  const loading = loadingTeacher || loadingAdmin || loadingLearner;

  const linkUser = async (role: string, user: { emailAddress: string }) => {
    try {
      const variables = {
        variables: { id: id, emailAddress: user.emailAddress },
      };

      if (role === "teacher") await linkTeacher(variables);
      if (role === "admin") await linkAdmin(variables);
      if (role === "learner") await linkLearner(variables);

      showNotification(`${capitalize(role)} added to educational institute.`);
      refreshView();
    } catch {
      showNotification(
        `Adding ${role} to educational institute failed!`,
        "warning",
      );
    }
    refreshView();
  };

  return (
    <Edit title={<Title {...props} />} undoable={false} {...props}>
      <TabbedForm>
        <FormTab label="Basic">
          <DisabledInput source="id" />
          <TextInput
            label="Domain"
            source="domain"
            validate={[required(), minLength(1), maxLength(100)]}
          />
          <ArrayInput source="names" validate={nameValidators}>
            <SimpleFormIterator>
              <SelectInput
                source="languageTag"
                choices={[
                  { id: "fi-FI", name: "Finnish" },
                  { id: "sv-FI", name: "Swedish" },
                ]}
              />
              <TextInput source="translation" validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Teachers" path="teachers">
          <div style={{ margin: "16px 0px" }}>
            <MuiTextField
              value={search}
              fullWidth
              onChange={e => onSearch(e.target.value)}
              label="Search..."
              type="search"
            />
          </div>
          <ReferenceManyField
            addLabel={false}
            perPage={10}
            reference="User"
            target={"organization.id"}
            filter={{ role: "teacher", search }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="emailAddress" />
              <EditButton basePath="/User" />
              <DeleteButton parentRecordId={id} role="teacher" />
            </Datagrid>
          </ReferenceManyField>
          {!loading && (
            <WrapUserSearch
              {...props}
              role="teacher"
              onSelect={linkUser.bind(null, "teacher")}
            />
          )}
        </FormTab>
        <FormTab label="admins" path="admins">
          <div style={{ margin: "16px 0px" }}>
            <MuiTextField
              value={search}
              fullWidth
              onChange={e => setSearch(e.target.value)}
              label="Search..."
              type="search"
            />
          </div>

          <ReferenceManyField
            addLabel={false}
            perPage={10}
            reference="User"
            target={"organization.id"}
            filter={{ role: "organizationAdmin", search }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="emailAddress" />
              <EditButton basePath="/User" />
              <DeleteButton parentRecordId={id} role="admin" />
            </Datagrid>
          </ReferenceManyField>
          {!loading && (
            <WrapUserSearch
              {...props}
              role="admin"
              onSelect={linkUser.bind(null, "admin")}
            />
          )}
        </FormTab>
        <FormTab label="learners" path="learners">
          <div style={{ margin: "16px 0px" }}>
            <MuiTextField
              value={search}
              fullWidth
              onChange={e => setSearch(e.target.value)}
              label="Search..."
              type="search"
            />
          </div>
          <ReferenceManyField
            addLabel={false}
            perPage={10}
            reference="User"
            target={"organization.id"}
            filter={{ role: "learner", search }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="emailAddress" />
              <EditButton basePath="/User" />
              <DeleteButton parentRecordId={id} role="learner" />
            </Datagrid>
          </ReferenceManyField>
          {!loading && (
            <WrapUserSearch
              {...props}
              role="learner"
              onSelect={linkUser.bind(null, "learner")}
            />
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const enhance = connect(
  null,
  { showNotification: showNotificationAction, refreshView: refreshViewAction },
);

export default enhance(OrganizationEdit);
